import React from "react";
import Title from "./Title";
import Project from "./Project";
import { Link } from "gatsby";
const Projects = ({ title, projects, showLink }) => {
  return (
    <section className="section projects">
      <Title title={title} />
      <div className=" projects-container">
        {projects.map((project, index) => {
          return <Project key={project.id} index={index} {...project} />;
        })}
      </div>
      {showLink && (
        <Link to="/projects" className="btn center-btn">
          Projects
        </Link>
      )}
    </section>
  );
};

export default Projects;
