import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Projects from "../components/Projects";
import SEO from "../components/SEO";
const Index = ({ data }) => {
  const {
    allStrapiProjects: { nodes: projects },
  } = data;
  return (
    <Layout>
      <SEO
        title="Miguel's Portfolio"
        description="Miguel Ramirez's porftolio projects"
      />
      <Projects projects={projects} title="Projects" />
    </Layout>
  );
};

export const query = graphql`
  {
    allStrapiProjects(
      filter: { featured: { eq: true } }
      sort: { order: ASC, fields: strapiId }
    ) {
      nodes {
        description
        GitHub
        id
        title
        url
        Info
        image {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              layout: FULL_WIDTH
            )
          }
        }
        stack {
          id
          title
        }
      }
    }
  }
`;
export default Index;
