import React from "react";

import { FaGithubSquare, FaShareSquare } from "react-icons/fa";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";
const Project = ({
  description,
  title,
  GitHub,
  image,
  stack,
  url,
  index,
  Info,
}) => {
  const img = getImage(image);

  return (
    <article className="project">
      <GatsbyImage image={img} className="project__img" alt="image" />
      <div className="project__info">
        <h3 className="project__info__title">{title}</h3>
        {/* <p className="project-desc">{description}</p> */}
        <ReactMarkdown source={Info} />
        <div className="project__stack">
          {stack.map(item => {
            return <span key={item.id}>{item.title}</span>;
          })}
        </div>
        <div className="project__links">
          <a href={GitHub} className="project__links__link">
            <span>Github</span>
            <FaGithubSquare className="project-icon"></FaGithubSquare>
          </a>
          <a href={url} className="project__links__link">
            <span>Demo</span>
            <FaShareSquare className="project-icon"></FaShareSquare>
          </a>
        </div>
      </div>
    </article>
  );
};

Project.propTypes = {};

export default Project;
